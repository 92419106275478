import React from 'react';
import Alert from './index';

export default function AlertProvider(props) {
  const [popup, setPopup] = React.useState(undefined);
  Alert.setProvider(setPopup);
  const { children } = props;
  return (
    <>
      {popup}
      {children}
    </>
  );
}
